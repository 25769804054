import React, { useState } from 'react';
import './Owners.css';
import RobsCalc from './RobsCalc'; // Ensure RobsCalc is properly imported

const owners = [
  { name: "D", team: "Team 1", record: "0-0", bio: "The Reasonable One", image: "logo.svg" },
  { name: "Manny", team: "Team 2", record: "0-0", bio: "The Finance/Crypto Resource", image: "manny.jpg" },
  { name: "Terry", team: "Team 3", record: "0-0", bio: "The Waiver wire Sniper", image: "terry.jpg" },
  { name: "Chris", team: "Team 4", record: "0-0", bio: "The Boss", image: "chris.jpg" },
  { name: "AJ", team: "Team 5", record: "0-0", bio: "The Real One", image: "aj.jpg" },
  { name: "Gary", team: "Team 6", record: "0-0", bio: "The Future President", image: "gary.jpg" },
  { name: "Filter", team: "Team 7", record: "0-0", bio: "The Preparation Artist", image: "logo.svg" },
  { name: "Kenny", team: "Team 8", record: "0-0", bio: "The Daredevil", image: "kenny.jpg" },
  { name: "Izzy", team: "Team 9", record: "0-0", bio: "The Host", image: "logo.svg" },
  { name: "Zo", team: "Team 10", record: "0-0", bio: "The Serial Pick-Trader", image: "logo.svg" },
  { name: "Brian", team: "Team 11", record: "0-0", bio: "The Debbie Downer", image: "logo.png" },
  { name: "Rob", team: "Team 12", record: "0-0", bio: "The Temper Tantrum", image: "robert.jpg" },
];

const Owners = () => {
  const [showCalculator, setShowCalculator] = useState(false);

  // Handle click on Robert's image
  const handleImageClick = (image) => {
    if (image === 'robert.jpg') {
      const password = prompt('Please enter the password:');
      if (password === '741741') {
        setShowCalculator(true);
      } else {
        alert('Incorrect password.');
      }
    }
  };

  return (
    <section id="owners">
      <div className="owners-container">
        {showCalculator ? (
          <RobsCalc />
        ) : (
          owners.map((owner, index) => (
            <div key={index} className="owner-box" onClick={() => handleImageClick(owner.image)}>
              <img src={owner.image} alt={`${owner.name} Logo`} className="owner-image" />
              <div className="owner-details">
                <div>Name: {owner.name}</div>
                <div>Team: {owner.team}</div>
                <div>Record: {owner.record}</div>
                <div>Bio: {owner.bio}</div>
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  );
};

export default Owners;
