import React from 'react';
import './Schedule.css'; // Ensure you have appropriate CSS for styling

const schedule = [
  { week: 1, date: "September 5 - September 9", matchups: [{ teams: "Team 1 vs Team 2", owners: "Owner 1 vs Owner 2" }, { teams: "Team 3 vs Team 4", owners: "Owner 3 vs Owner 4" }, { teams: "Team 5 vs Team 6", owners: "Owner 5 vs Owner 6" }, { teams: "Team 7 vs Team 8", owners: "Owner 7 vs Owner 8" }, { teams: "Team 9 vs Team 10", owners: "Owner 9 vs Owner 10" }, { teams: "Team 11 vs Team 12", owners: "Owner 11 vs Owner 12" }] },
  { week: 2, date: "September 12 - September 16", matchups: [{ teams: "Team 1 vs Team 3", owners: "Owner 1 vs Owner 3" }, { teams: "Team 2 vs Team 4", owners: "Owner 2 vs Owner 4" }, { teams: "Team 5 vs Team 7", owners: "Owner 5 vs Owner 7" }, { teams: "Team 6 vs Team 8", owners: "Owner 6 vs Owner 8" }, { teams: "Team 9 vs Team 11", owners: "Owner 9 vs Owner 11" }, { teams: "Team 10 vs Team 12", owners: "Owner 10 vs Owner 12" }] },
  { week: 3, date: "September 19 - September 23", matchups: [{ teams: "Team 1 vs Team 4", owners: "Owner 1 vs Owner 4" }, { teams: "Team 2 vs Team 3", owners: "Owner 2 vs Owner 3" }, { teams: "Team 5 vs Team 8", owners: "Owner 5 vs Owner 8" }, { teams: "Team 6 vs Team 7", owners: "Owner 6 vs Owner 7" }, { teams: "Team 9 vs Team 12", owners: "Owner 9 vs Owner 12" }, { teams: "Team 10 vs Team 11", owners: "Owner 10 vs Owner 11" }] },
  { week: 4, date: "September 26 - September 30", matchups: [{ teams: "Team 1 vs Team 5", owners: "Owner 1 vs Owner 5" }, { teams: "Team 2 vs Team 6", owners: "Owner 2 vs Owner 6" }, { teams: "Team 3 vs Team 7", owners: "Owner 3 vs Owner 7" }, { teams: "Team 4 vs Team 8", owners: "Owner 4 vs Owner 8" }, { teams: "Team 9 vs Team 10", owners: "Owner 9 vs Owner 10" }, { teams: "Team 11 vs Team 12", owners: "Owner 11 vs Owner 12" }] },
  { week: 5, date: "October 3 - October 7", matchups: [{ teams: "Team 1 vs Team 6", owners: "Owner 1 vs Owner 6" }, { teams: "Team 2 vs Team 5", owners: "Owner 2 vs Owner 5" }, { teams: "Team 3 vs Team 8", owners: "Owner 3 vs Owner 8" }, { teams: "Team 4 vs Team 7", owners: "Owner 4 vs Owner 7" }, { teams: "Team 9 vs Team 11", owners: "Owner 9 vs Owner 11" }, { teams: "Team 10 vs Team 12", owners: "Owner 10 vs Owner 12" }] },
  { week: 6, date: "October 10 - October 14", matchups: [{ teams: "Team 1 vs Team 7", owners: "Owner 1 vs Owner 7" }, { teams: "Team 2 vs Team 8", owners: "Owner 2 vs Owner 8" }, { teams: "Team 3 vs Team 5", owners: "Owner 3 vs Owner 5" }, { teams: "Team 4 vs Team 6", owners: "Owner 4 vs Owner 6" }, { teams: "Team 9 vs Team 10", owners: "Owner 9 vs Owner 10" }, { teams: "Team 11 vs Team 12", owners: "Owner 11 vs Owner 12" }] },
  { week: 7, date: "October 17 - October 21", matchups: [{ teams: "Team 1 vs Team 8", owners: "Owner 1 vs Owner 8" }, { teams: "Team 2 vs Team 7", owners: "Owner 2 vs Owner 7" }, { teams: "Team 3 vs Team 6", owners: "Owner 3 vs Owner 6" }, { teams: "Team 4 vs Team 5", owners: "Owner 4 vs Owner 5" }, { teams: "Team 9 vs Team 11", owners: "Owner 9 vs Owner 11" }, { teams: "Team 10 vs Team 12", owners: "Owner 10 vs Owner 12" }] },
  { week: 8, date: "October 24 - October 28", matchups: [{ teams: "Team 1 vs Team 9", owners: "Owner 1 vs Owner 9" }, { teams: "Team 2 vs Team 10", owners: "Owner 2 vs Owner 10" }, { teams: "Team 3 vs Team 11", owners: "Owner 3 vs Owner 11" }, { teams: "Team 4 vs Team 12", owners: "Owner 4 vs Owner 12" }, { teams: "Team 5 vs Team 6", owners: "Owner 5 vs Owner 6" }, { teams: "Team 7 vs Team 8", owners: "Owner 7 vs Owner 8" }] },
  { week: 9, date: "October 31 - November 4", matchups: [{ teams: "Team 1 vs Team 10", owners: "Owner 1 vs Owner 10" }, { teams: "Team 2 vs Team 9", owners: "Owner 2 vs Owner 9" }, { teams: "Team 3 vs Team 12", owners: "Owner 3 vs Owner 12" }, { teams: "Team 4 vs Team 11", owners: "Owner 4 vs Owner 11" }, { teams: "Team 5 vs Team 7", owners: "Owner 5 vs Owner 7" }, { teams: "Team 6 vs Team 8", owners: "Owner 6 vs Owner 8" }] },
  { week: 10, date: "November 7 - November 11", matchups: [{ teams: "Team 1 vs Team 11", owners: "Owner 1 vs Owner 11" }, { teams: "Team 2 vs Team 12", owners: "Owner 2 vs Owner 12" }, { teams: "Team 3 vs Team 9", owners: "Owner 3 vs Owner 9" }, { teams: "Team 4 vs Team 10", owners: "Owner 4 vs Owner 10" }, { teams: "Team 5 vs Team 6", owners: "Owner 5 vs Owner 6" }, { teams: "Team 7 vs Team 8", owners: "Owner 7 vs Owner 8" }] },
  { week: 11, date: "November 14 - November 18", matchups: [{ teams: "Team 1 vs Team 12", owners: "Owner 1 vs Owner 12" }, { teams: "Team 2 vs Team 11", owners: "Owner 2 vs Owner 11" }, { teams: "Team 3 vs Team 10", owners: "Owner 3 vs Owner 10" }, { teams: "Team 4 vs Team 9", owners: "Owner 4 vs Owner 9" }, { teams: "Team 5 vs Team 7", owners: "Owner 5 vs Owner 7" }, { teams: "Team 6 vs Team 8", owners: "Owner 6 vs Owner 8" }] },
  { week: 12, date: "November 21 - November 25", matchups: [{ teams: "Team 1 vs Team 6", owners: "Owner 1 vs Owner 6" }, { teams: "Team 2 vs Team 5", owners: "Owner 2 vs Owner 5" }, { teams: "Team 3 vs Team 8", owners: "Owner 3 vs Owner 8" }, { teams: "Team 4 vs Team 7", owners: "Owner 4 vs Owner 7" }, { teams: "Team 9 vs Team 11", owners: "Owner 9 vs Owner 11" }, { teams: "Team 10 vs Team 12", owners: "Owner 10 vs Owner 12" }] },
  { week: 13, date: "Week 13: Playoffs", matchups: [{ teams: "1 Seed on Bye", owners: "" }, { teams: "2 Seed on Bye", owners: "" }, { teams: "3 Seed vs 6 Seed", owners: "" }, { teams: "4 Seed vs 5 Seed", owners: "" }] },
  { week: 14, date: "Week 14: Playoffs", matchups: [{ teams: "Lowest Seeded Winner vs 1 Seed", owners: "" }, { teams: "Other Winner vs 2 Seed", owners: "" }] },
  { week: 15, date: "Week 15: Championship", matchups: [{ teams: "Championship Matchup", owners: "" }] }
];

const Schedule = () => {
  return (
    <section id="schedule">
      {schedule.map(({ week, date, matchups }) => (
        <div key={week} id={`week${week}`} className="week">
          <div className="week-header">
            <h3>Week {week}</h3>
            <span className="week-date">{date}</span>
          </div>
          <div className="matchups">
            {matchups.map((matchup, index) => (
              <div key={index} className="matchup">
                <div className="teams">{matchup.teams}</div>
                {matchup.owners && <div className="owners">{matchup.owners}</div>}
              </div>
            ))}
          </div>
        </div>
      ))}
    </section>
  );
};

export default Schedule;
