import React from 'react';
import './News.css'; // Ensure you have appropriate CSS for styling

const News = () => {
  return (
    <div className="news">
      <div className="news-article">
          <h1 className="news-title">Speculation Arises Over Robert's Behavior at the Upcoming Draft Day</h1>
          <p className="news-meta">Date: July 8, 2024 | Author: John Smith</p>
          <p className="news-content">
            As the highly anticipated draft day approaches for the Golden Goose Fantasy Football League, league members and fans alike are buzzing with speculation over whether Robert will have another incident at this year's event. Known for his passionate outbursts and competitive spirit, Robert's behavior has been a focal point in previous drafts.
          </p>
          <p className="news-content">
            Last year's draft was particularly memorable, as Robert's temper flared during a heated exchange over a contested pick, leading to a brief but intense altercation. "Robert's passion for the game is undeniable," said a fellow league member. "But sometimes that passion can boil over, especially on draft day when the stakes feel high."
          </p>
          <p className="news-content">
            Despite the drama, many in the league appreciate the excitement that Robert brings. "He's a key personality in our league," said Gary Scott, the league's commissioner. "His intensity and drive to win are unmatched. It wouldn't be draft day without some fireworks from Robert."
          </p>
          <p className="news-content">
            Speculation about Robert's behavior this year has been fueled by recent comments he made in the league's group chat. "This year is going to be different," Robert wrote. "I'm coming in with a clear head and a solid strategy. But don't test me." These remarks have left many wondering if he will indeed keep his cool or if another incident is inevitable.
          </p>
          <p className="news-content">
            "We're all a bit on edge," admitted Jane Smith, another league member. "Robert is unpredictable, and that's part of the thrill of having him in the league. But we all hope that this year will be more about the game and less about the drama."
          </p>
          <p className="news-content">
            The league has taken steps to ensure a smoother draft day this year, including implementing stricter rules on conduct and a more structured draft process. "We're prepared for anything," said Brian Sheckman, the co-commissioner. "We've put measures in place to keep things under control and focus on what matters—the draft and the competition."
          </p>
          <p className="news-content">
            As the countdown to draft day continues, all eyes will be on Robert and whether he can maintain his composure under the pressure. Will this be the year he turns over a new leaf, or will history repeat itself with another memorable incident? Only time will tell.
          </p>
        </div>
      <div className="news-article">                
        <h1 className="news-title">Speculation Arises Over Bobby's Participation in the Upcoming Season</h1>
          <p className="news-meta">Date: July 4, 2024 | Author: Doja Cat</p>
          <p className="news-content">
            As the new fantasy football season approaches, speculation has arisen over the participation of one of the league's most enigmatic figures, Bobby. Known for his unpredictable moves and unconventional strategies, Bobby's involvement has always been a topic of intense discussion among league members.
          </p>
          <p className="news-content">
            Sources close to Bobby suggest that he is currently undecided about joining the Golden Goose Fantasy Football League for the 2024 season. "Bobby is weighing his options," said a close friend who wished to remain anonymous. "He has a lot on his plate right now, but he loves the game and the camaraderie of the league."
          </p>
          <p className="news-content">
            Bobby's potential absence could significantly impact the league's dynamics. His daring draft choices and bold trades have often been a source of excitement and controversy. "Bobby brings a unique flavor to the league," said Gary Scott, the league's commissioner. "His decisions keep everyone on their toes and add a layer of unpredictability that we all enjoy."
          </p>
          <p className="news-content">
            Despite the uncertainty, many league members are hopeful that Bobby will return. "It wouldn't be the same without him," said Jane Smith, a long-time league member. "He's a wildcard, and we need that kind of energy. Plus, his trash talk is legendary."
          </p>
          <p className="news-content">
            While Bobby's final decision remains unknown, the league is preparing for all possibilities. "We're ready for him, whether he joins or not," said Brian Sheckman, the co-commissioner. "The draft will go on, and we'll have a great season regardless. But of course, we all hope Bobby will be part of it."
          </p>
          <p className="news-content">
            As the draft day approaches, all eyes will be on Bobby and his decision. Will he return to defend his reputation and thrill his fellow league members once again? Only time will tell.
          </p>
      </div>
      <div className="news-article">
        <h1 className="news-title">Speculation Surrounds Key Issues Ahead of Tonight's Call</h1>
        <p className="news-meta">Date: July 3, 2024 | Author: Rando McGPT</p>
        <p className="news-content">
          As the Golden Goose Fantasy Football League prepares for tonight's crucial call, speculation is rampant over several key issues that could shape the upcoming season. Among the topics on the docket are the question of who will be the new commissioner, the location of the draft, and whether entry fees and prizes will see an increase.
        </p>
        <p className="news-content">
          The role of commissioner has always been a pivotal one, guiding the league through its many seasons. With the current commissioner stepping down, the big question is who will step up to take the reins. "There are a few strong candidates," said a league insider. "It's going to come down to who the members feel can best lead us through the upcoming season and beyond."
        </p>
        <p className="news-content">
          Another hot topic is the location of this year's draft. Traditionally held at various memorable venues, the draft's location is always a point of excitement and debate. "We want a place that's convenient for everyone but also adds a special touch to the event," said Gary Scott, the outgoing commissioner. "We've had some amazing spots in the past, and we want to continue that tradition."
        </p>
        <p className="news-content">
          Adding to the suspense is the question of whether entry fees and prize amounts will rise this year. The league has seen its expenses increase, and many are wondering if these costs will be passed on to the members. "It's a delicate balance," said Brian Sheckman, the co-commissioner. "We need to cover our costs but also keep the league accessible and fun for everyone."
        </p>
        <p className="news-content">
          Tonight's call is expected to address these issues and more, setting the stage for what promises to be an exciting season. "We're all eager to hear the decisions," said Jane Smith, a long-time league member. "There's a lot at stake, and these discussions will shape our strategy and planning for the draft and the season ahead."
        </p>
        <p className="news-content">
          As the league members prepare for tonight's call, anticipation is high. Will a new commissioner be chosen? Where will the draft be held? And will prices rise? These items and more are on the docket for tonight's call, and the answers will surely set the tone for the rest of the year.
        </p>
      </div>
    </div>
  );
};

export default News;
