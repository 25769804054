import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import NavBar from './NavBar';
import Countdown from './Countdown';
import News from './News';
import DraftPicks from './DraftPicks';
import Schedule from './Schedule';
import Rules from './Rules';
import Owners from './Owners';
import Leadership from './Leadership';
import PastChampions from './PastChampions';

const App = () => {
  return (
    <Router>
      <div className="App">
        <header>
          <img src="./logo.png" alt="looseygoosey" className="looseygoosey" />
          <span>The Golden Goose Fantasy</span>
        </header>
        <NavBar />
        <Countdown />
        <Routes>
          <Route path="/" element={<News />} />
          <Route path="/draft-board/*" element={<DraftPicks />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/owners" element={<Owners />} />
          <Route path="/leadership" element={<Leadership />} />
          <Route path="/past-champions" element={<PastChampions />} />
        </Routes>
        <footer>
          © 2024 The Golden Goose Fantasy Football League
        </footer>
      </div>
    </Router>
  );
};

export default App;
