import React from 'react';
import './Rules.css'; // Ensure you have appropriate CSS for styling

const Rules = () => {
  return (
    <div className="rules">
      <section id="article1">
        <h2>Article I. League Entry and Prize Payout Structure</h2>
        <p>Welcome to The Golden Goose redraft league. Hopefully everyone will enjoy themselves, stop being sensitive, don't leave the chat and some will win some cash.</p>
        <h3>Entry Fee, Prizes and Punishments</h3>
        <ul>
          <li>$200.00 must be paid before the draft positions are chosen unless special circumstances are discussed with the commissioner</li>
          <li>1st Place $1,400.00</li>
          <li>2nd Place $600.00</li>
          <li>3rd Place $200.00</li>
          <li>Most Points (end of regular season) $200.00</li>
          <li>Best Team Drafted (voted by league on draft day) - Surprise Gift</li>
          <li>Worst Team Drafted (voted by league on draft day) - Surprise Gift</li>
          <li>Fist Kicker - Socks</li>
          <li>The team manager that finishes in last place after the <strong>regular season</strong> will wear a dress during next year’s draft party for the first 3 rounds or pay a fine of $150 which will be divided between team managers not including the team manager that is paying the fine. If this rule is not adhered to by the team manager, the team manager will be removed from the league.</li>
        </ul>
      </section>
      <section id="article2">
        <h2>Article II. Roster and Lineups</h2>
        <h3>Starters</h3>
        <ul>
          <li>QB</li>
          <li>RB</li>
          <li>RB</li>
          <li>WR</li>
          <li>WR</li>
          <li>WR</li>
          <li>TE</li>
          <li>FLEX (RB, WR, TE)</li>
          <li>DEF</li>
          <li>K</li>
        </ul>
        <h3>Bench</h3>
        <ul>
          <li>7 BENCH SPOTS</li>
          <li>1 IR SPOT</li>
        </ul>
        <h3>IR Spot</h3>
        <p>There is one IR spot per team. There are no Covid IR spots this season. The IR spot is only for players who have the following designations (IR, IR-R, PUP-R, NFI, NFI-R). Players with OUT and SUSP are not eligible. After a player is activated from such designation, you are to remove the player from IR within 24 hours. You will be notified by the commissioner that the player has come off IR. If not done after the 24 hours, the player will be removed from the team and placed on the free agents list.</p>
      </section>
      <section id="article3">
        <h2>Article III. General Rules and Chat Rules</h2>
        <ul>
          <li>If owner abandons team during the season said team owner will be removed from the league for following season</li>
          <li>Commissioner will be responsible to ensure the most competitive line up is set for each week by making sure the highest projection players are in the starting line up. If such team happens to win the championship then the 2nd place team will be the champion</li>
          <li>If a team is not in the playoffs, the team may not drop or pick up players after regular season</li>
          <li>All ties in playoffs will be decided by most points in regular season</li>
          <li>If same number of points, then go to head to head record</li>
          <li>If have same head to head record will be decided by coin flip</li>
        </ul>
        <h3>Chat Rules</h3>
        <ul>
          <li>All team owners must participate within the league and its group chat</li>
          <li>Includes responding to trade offer with as simple as a decline</li>
          <li>If accused by member of league to be non-participant will be put to remaining 11 owners on league vote whether you remain for following season or not</li>
        </ul>
      </section>
      <section id="article4">
        <h2>Article IV. Standings</h2>
        <p>Your standings are based on your head-to-head record. If there is a tie the tie breaker will be based on points scored. Our season goes from weeks 1 through 14. After week 14 the season is over. The bottom 6 teams are locked into their standings. The top 6 standings will be determined via playoffs.</p>
      </section>
      <section id="article5">
        <h2>Article V. Playoffs</h2>
        <ul>
          <li>Top teams 1-6 based off record</li>
          <li>Teams in 1st and 2nd place get a bye week on week 15</li>
          <li>Team in 3rd place will play team in 6th place</li>
          <li>Team in 4th place will play team in 5th place</li>
          <li>If teams have the same record, see article III subsection c, d and e</li>
        </ul>
        <p>Playoffs will be reseeded after week 15. The 1 seed will play the lowest seeded team from week 15. The 2 seed will play the higher seed from week 15. The quarterfinals will determine the standings for 5th and 6th place. The teams that finished 7th through 12th during the regular season (week 1 through week 14) will finish the season in those respective positions. The semifinal and final will determine the top 4 standings. The winners of the semifinals will play for 1st and 2nd. The losers will play for 3rd place. The loser of the 3rd place match up will finish in 4th place. Only teams in the playoffs will have access to the waiver wire FAAB.</p>
      </section>
      <section id="article6">
        <h2>Article VI. Waiver Wire (FAAB)</h2>
        <p>The waiver wire will now be done through FAAB every Tuesday and processed early Wednesday morning. Each owner will have a $100 budget to spend on waiver picks through the season. If you are eliminated during playoffs and not competing for 1st, 2nd or 3rd. You will no longer be allowed to access waivers. FAAB stands for Free Agent Auction Budget. It is a waiver system that gives every manager an equal opportunity at picking up free agents off waivers. FAAB uses a blind bidding system where each manager submits a bid for a player in the form of a dollar amount. Managers organize those bids based on preference. Then, the system awards the free agents to the highest bidders.</p>
        <h3>Important:</h3>
        <p>Once a player has started his game, you are not allowed to drop that player until following weeks waivers.</p>
      </section>
      <section id="article7">
        <h2>Article VII. Scoring and Settings</h2>
        <p>See Yahoo League Settings. Scoring and Settings</p>
      </section>
      <section id="article8">
        <h2>Article VIII. Contacting League Officers</h2>
        <p>Your league officers are available if you have any questions or are in need of assistance. If your question pertains to a feature provided by our fantasy football hosting provider Yahoo, please utilize their support resources before contacting a league officer. For league-specific issues, please contact the main league commissioner first or the co-commissioner second if the main commissioner is not available. If you cannot contact the main commissioner or co-commissioner, and your issue is time-sensitive (for instance, if you are requesting a roster modification because you don't have access to the Internet), please use the WhatsApp chat to make your request. In this way, we can verify the time of your request and make your roster change retroactively if necessary.</p>
        <h3>Main League Commissioner</h3>
        <p>Chris Mez<br></br>
        (407) 780-0840<br></br>
        whateverchrissemailsi@gmail.com</p>
</section>

<section id="article9">
    <h2>Article IX. Creating New League Rules</h2>
    <p>When a league dispute arises which has not been addressed in the league constitution, a discussion will ensue on possible resolutions to the dispute. Based on these discussions, the Commissioner will create an online poll so that owners can vote on an appropriate course of action. The league will follow the course of action based on a plurality of league votes. Also, we will create a new rule in the league constitution based on the result of the league poll so that similar disputes will be resolved in the same manner.</p>
</section>

<section id="article10">
    <h2>Article X. Penalties for Roster Oversights</h2>
    <p>If an owner has a roster slot open (either due to bye week or simple oversight), but has a backup player (not on bye, or OUT) available on their bench, then the commissioner will insert the available player in the open roster slot. If two eligible bench players exist then the player with the highest projections would be inserted in the starting line up. The commissioner will not make a substitution for a player who is OUT, regardless of when they were declared out. The one exception is when a roster is illegal, in which case substitutions must be made by the league commissioner.</p>
</section>

    </div>
  );
};

export default Rules;
