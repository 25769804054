import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';

const NavBar = () => {
  return (
    <nav className="navbar">
      <Link to="/">News</Link>
      <Link to="/draft-board">Draft Board</Link>
      <Link to="/schedule">Schedule</Link>
      <Link to="/rules">Rules</Link>
      <Link to="/owners">Owners</Link>
      <Link to="/leadership">Leadership</Link>
      <Link to="/past-champions">Past Champs</Link>
    </nav>
  );
};

export default NavBar;
