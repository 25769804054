import React, { useState, useEffect } from 'react';

const Countdown = () => {
  const calculateTimeLeft = () => {
    const eventDate = new Date("September 1, 2024 14:00:00").getTime();
    const now = new Date().getTime();
    const distance = eventDate - now;

    const timeLeft = {
      days: Math.floor(distance / (1000 * 60 * 60 * 24)),
      hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((distance % (1000 * 60)) / 1000),
    };

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="countdown">
      <h2>Countdown to Draft Day:</h2>
      <div id="countdown">
        {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m 
      </div>
      <h2 className="space">Location: Izzy's</h2>
    </div>
  );
};

export default Countdown;
