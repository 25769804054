import React from 'react';
import './Leadership.css'; // Ensure you have appropriate CSS for styling

const pastCommissioners = [
  { name: "Commissioner 1", tenure: "2010 - 2013" },
  { name: "Commissioner 2", tenure: "2013 - 2016" },
  { name: "Commissioner 3", tenure: "2016 - 2018" },
  { name: "Commissioner 4", tenure: "2018 - 2020" },
  { name: "Commissioner 5", tenure: "2020 - 2023" },
];

const Leadership = () => {
  return (
    <section id="leadership">
      <div className="current-commissioner">
        <img src="chris.jpg" alt="Commissioner" className="commissioner-image" />
        <h1 className="title">Commissioner</h1>
        <h2 className="name">Chris</h2>
      </div>
      <div className="past-commissioners">
        <h3>Past Commissioners</h3>
        <table className="leadership-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Tenure</th>
            </tr>
          </thead>
          <tbody>
            {pastCommissioners.map((comm, index) => (
              <tr key={index}>
                <td>{comm.name}</td>
                <td>{comm.tenure}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Leadership;
