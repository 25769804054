import React, { useState } from 'react';
import './DraftCalculator.css';

const pickValues = {
  1: 90.3, 2: 78.3, 3: 71.3, 4: 66.4, 5: 62.5, 6: 59.4, 7: 56.7, 8: 54.4, 9: 52.3, 10: 50.5,
  11: 48.9, 12: 47.4, 13: 46, 14: 44.7, 15: 43.5, 16: 42.4, 17: 41.4, 18: 40.4, 19: 39.4, 20: 38.5,
  21: 37.7, 22: 36.9, 23: 36.1, 24: 35.4, 25: 34.7, 26: 34, 27: 33.4, 28: 32.7, 29: 32.1, 30: 31.5,
  31: 31, 32: 30.4, 33: 29.9, 34: 29.4, 35: 28.9, 36: 28.4, 37: 27.9, 38: 27.4, 39: 27, 40: 26.6,
  41: 26.1, 42: 25.7, 43: 25.3, 44: 24.9, 45: 24.5, 46: 24.1, 47: 23.8, 48: 23.4, 49: 23.1, 50: 22.7,
  51: 22.4, 52: 22, 53: 21.7, 54: 21.4, 55: 21.1, 56: 20.7, 57: 20.4, 58: 20.1, 59: 19.8, 60: 19.6,
  61: 19.3, 62: 19, 63: 18.7, 64: 18.4, 65: 18.2, 66: 17.9, 67: 17.6, 68: 17.4, 69: 17.1, 70: 16.9,
  71: 16.6, 72: 16.4, 73: 16.2, 74: 15.9, 75: 15.7, 76: 15.5, 77: 15.2, 78: 15, 79: 14.8, 80: 14.6,
  81: 14.4, 82: 14.2, 83: 13.9, 84: 13.7, 85: 13.5, 86: 13.3, 87: 13.1, 88: 12.9, 89: 12.7, 90: 12.5,
  91: 12.4, 92: 12.2, 93: 12, 94: 11.8, 95: 11.6, 96: 11.4, 97: 11.2, 98: 11.1, 99: 10.9, 100: 10.7,
  101: 10.6, 102: 10.4, 103: 10.2, 104: 10, 105: 9.9, 106: 9.7, 107: 9.6, 108: 9.4, 109: 9.2, 110: 9.1,
  111: 8.9, 112: 8.8, 113: 8.6, 114: 8.5, 115: 8.3, 116: 8.2, 117: 8, 118: 7.9, 119: 7.7, 120: 7.6,
  121: 7.4, 122: 7.3, 123: 7.1, 124: 7, 125: 6.9, 126: 6.7, 127: 6.6, 128: 6.5, 129: 6.3, 130: 6.2,
  131: 6.1, 132: 5.9, 133: 5.8, 134: 5.7, 135: 5.5, 136: 5.4, 137: 5.3, 138: 5.2, 139: 5, 140: 4.9,
  141: 4.8, 142: 4.7, 143: 4.5, 144: 4.4, 145: 4.3, 146: 4.2, 147: 4.1, 148: 3.9, 149: 3.8, 150: 3.7,
  151: 3.6, 152: 3.5, 153: 3.4, 154: 3.3, 155: 3.1, 156: 3, 157: 2.9, 158: 2.8, 159: 2.7, 160: 2.6,
  161: 2.5, 162: 2.4, 163: 2.3, 164: 2.2, 165: 2.1, 166: 2, 167: 1.9, 168: 1.8, 169: 1.7, 170: 1.6,
  171: 1.4, 172: 1.3, 173: 1.2, 174: 1.1, 175: 1, 176: 1, 177: 0.9, 178: 0.8, 179: 0.7, 180: 0.6,
  181: 0.5, 182: 0.5, 183: 0.5, 184: 0.5, 185: 0.5, 186: 0.4, 187: 0.4, 188: 0.4, 189: 0.4, 190: 0.4,
  191: 0.4, 192: 0.4, 193: 0.3, 194: 0.3, 195: 0.3, 196: 0.3, 197: 0.2, 198: 0.2, 199: 0.2, 200: 0.2,
  201: 0.1, 202: 0.1, 203: 0.1, 204: 0.1
};


const pickToNumber = {
  "1.01": 1, "1.02": 2, "1.03": 3, "1.04": 4, "1.05": 5, "1.06": 6, "1.07": 7, "1.08": 8, "1.09": 9, "1.10": 10,
  "1.11": 11, "1.12": 12, "2.01": 13, "2.02": 14, "2.03": 15, "2.04": 16, "2.05": 17, "2.06": 18, "2.07": 19, "2.08": 20,
  "2.09": 21, "2.10": 22, "2.11": 23, "2.12": 24, "3.01": 25, "3.02": 26, "3.03": 27, "3.04": 28, "3.05": 29, "3.06": 30,
  "3.07": 31, "3.08": 32, "3.09": 33, "3.10": 34, "3.11": 35, "3.12": 36, "4.01": 37, "4.02": 38, "4.03": 39, "4.04": 40,
  "4.05": 41, "4.06": 42, "4.07": 43, "4.08": 44, "4.09": 45, "4.10": 46, "4.11": 47, "4.12": 48, "5.01": 49, "5.02": 50,
  "5.03": 51, "5.04": 52, "5.05": 53, "5.06": 54, "5.07": 55, "5.08": 56, "5.09": 57, "5.10": 58, "5.11": 59, "5.12": 60,
  "6.01": 61, "6.02": 62, "6.03": 63, "6.04": 64, "6.05": 65, "6.06": 66, "6.07": 67, "6.08": 68, "6.09": 69, "6.10": 70,
  "6.11": 71, "6.12": 72, "7.01": 73, "7.02": 74, "7.03": 75, "7.04": 76, "7.05": 77, "7.06": 78, "7.07": 79, "7.08": 80,
  "7.09": 81, "7.10": 82, "7.11": 83, "7.12": 84, "8.01": 85, "8.02": 86, "8.03": 87, "8.04": 88, "8.05": 89, "8.06": 90,
  "8.07": 91, "8.08": 92, "8.09": 93, "8.10": 94, "8.11": 95, "8.12": 96, "9.01": 97, "9.02": 98, "9.03": 99, "9.04": 100,
  "9.05": 101, "9.06": 102, "9.07": 103, "9.08": 104, "9.09": 105, "9.10": 106, "9.11": 107, "9.12": 108, "10.01": 109, "10.02": 110,
  "10.03": 111, "10.04": 112, "10.05": 113, "10.06": 114, "10.07": 115, "10.08": 116, "10.09": 117, "10.10": 118, "10.11": 119, "10.12": 120,
  "11.01": 121, "11.02": 122, "11.03": 123, "11.04": 124, "11.05": 125, "11.06": 126, "11.07": 127, "11.08": 128, "11.09": 129, "11.10": 130,
  "11.11": 131, "11.12": 132, "12.01": 133, "12.02": 134, "12.03": 135, "12.04": 136, "12.05": 137, "12.06": 138, "12.07": 139, "12.08": 140,
  "12.09": 141, "12.10": 142, "12.11": 143, "12.12": 144, "13.01": 145, "13.02": 146, "13.03": 147, "13.04": 148, "13.05": 149, "13.06": 150,
  "13.07": 151, "13.08": 152, "13.09": 153, "13.10": 154, "13.11": 155, "13.12": 156, "14.01": 157, "14.02": 158, "14.03": 159, "14.04": 160,
  "14.05": 161, "14.06": 162, "14.07": 163, "14.08": 164, "14.09": 165, "14.10": 166, "14.11": 167, "14.12": 168, "15.01": 169, "15.02": 170,
  "15.03": 171, "15.04": 172, "15.05": 173, "15.06": 174, "15.07": 175, "15.08": 176, "15.09": 177, "15.10": 178, "15.11": 179, "15.12": 180,
  "16.01": 181, "16.02": 182, "16.03": 183, "16.04": 184, "16.05": 185, "16.06": 186, "16.07": 187, "16.08": 188, "16.09": 189, "16.10": 190,
  "16.11": 191, "16.12": 192, "17.01": 193, "17.02": 194, "17.03": 195, "17.04": 196, "17.05": 197, "17.06": 198, "17.07": 199, "17.08": 200,
  "17.09": 201, "17.10": 202, "17.11": 203, "17.12": 204
};

// Updated Fantasy Football Snake Draft Picks with Trade
const teamPicks = {
    Filter: ["2.05", "2.12", "3.01", "3.08", "3.09", "6.04", "6.12", "7.01", "9.01", "10.12", "11.09", "13.01", "14.12", "15.01", "15.12", "16.12", "17.01"],
    AJ: ["1.06", "2.11", "3.02", "4.11", "5.02", "5.06", "5.12", "6.11", "10.11", "11.02", "12.11", "13.02", "14.11", "15.02", "16.11", "17.02", "17.12"],
    Kenny: ["1.03", "2.10", "3.03", "4.10", "5.03", "6.10", "7.03", "8.10", "9.03", "10.10", "11.03", "12.10", "13.03", "14.10", "15.03", "16.10", "17.03"],
    D: ["1.04", "2.09", "3.04", "4.09", "5.04", "6.09", "7.04", "8.09", "9.04", "10.09", "11.04", "12.09", "13.04", "14.09", "15.04", "16.09", "17.04"],
    Gary: ["1.05","2.01", "3.12", "4.08", "5.05", "6.08", "7.05", "8.08", "9.05", "11.05", "12.08", "13.05", "14.01", "14.08", "15.05", "16.08", "17.05"],
    Manny: ["1.02", "2.07", "3.06", "4.07", "6.07", "7.02", "7.06", "8.07", "9.06", "10.07", "11.06", "12.07", "13.06", "14.07", "15.06", "16.07", "17.06"],
    Izzy: ["1.07", "2.04", "2.06", "4.12", "6.06", "8.04", "8.06", "9.07", "10.06", "11.07", "12.06", "13.07", "13.09", "14.06", "15.07", "16.06", "17.07"],
    Scheck: ["1.01", "1.08", "4.05", "5.08", "6.05", "7.08", "8.05", "9.08", "10.05", "11.01", "11.08", "12.05", "13.08", "14.05", "15.08", "16.05", "17.08"],
    Zo: ["1.09", "2.08", "4.01", "4.04", "4.06", "5.07", "7.07", "8.01", "9.02", "9.09", "9.12", "12.04", "13.12", "14.04", "15.09", "16.04", "17.09"],    
    Chris: ["1.10", "2.03", "3.10", "4.03", "5.10", "6.03", "7.10", "8.03", "9.10", "10.03", "11.10", "12.03", "13.10", "14.03", "15.10", "16.03", "17.10"],
    Terry: ["1.11", "1.12", "2.02", "5.11", "6.02", "7.11", "8.02", "9.11", "10.01", "10.02", "11.11", "12.02", "13.11", "14.02", "15.11", "16.02", "17.11"],    
    Rob: ["3.05", "3.07", "3.11", "4.02", "5.01", "5.09", "6.01", "7.09", "7.12", "8.11", "8.12", "10.04", "10.08", "11.12", "12.01", "12.12", "16.01"],
  };

const DraftCalculator = () => {
    const [yourTeam, setYourTeam] = useState('');
    const [trades, setTrades] = useState([{
      receiverTeam: '',
      sendingPicks: [''],
      receivingPicks: ['']
    }]);

    const calculateValue = (picks) => {
        return picks.reduce((total, pick) => {
            const pickNumber = pickToNumber[pick];
            return total + (pickValues[pickNumber] || 0);
        }, 0);
    };

    const calculateDifference = (sendingPicks, receivingPicks) => {
        const sendingValue = calculateValue(sendingPicks);
        const receivingValue = calculateValue(receivingPicks);
        const valueDifference = receivingValue - sendingValue;

        const sendingPositions = sendingPicks.map(pick => pickToNumber[pick] || 0).reduce((acc, num) => acc + num, 0);
        const receivingPositions = receivingPicks.map(pick => pickToNumber[pick] || 0).reduce((acc, num) => acc + num, 0);

        const positionsDifference = sendingPositions - receivingPositions;

        return { valueDifference, positionsDifference };
    };

    const handleAddPick = (tradeIndex) => {
        const newTrades = [...trades];
        newTrades[tradeIndex].sendingPicks.push('');
        newTrades[tradeIndex].receivingPicks.push('');
        setTrades(newTrades);
    };

    const handleRemovePick = (tradeIndex) => {
        const newTrades = [...trades];
        if (newTrades[tradeIndex].sendingPicks.length > 1 && newTrades[tradeIndex].receivingPicks.length > 1) {
            newTrades[tradeIndex].sendingPicks.pop();
            newTrades[tradeIndex].receivingPicks.pop();
            setTrades(newTrades);
        } else {
            newTrades.splice(tradeIndex, 1);  // Remove the trade if only one pick left
            setTrades(newTrades);
        }
    };

    const handleAddTradePartner = () => {
        setTrades([...trades, {
            receiverTeam: '',
            sendingPicks: [''],
            receivingPicks: ['']
        }]);
    };

    const getUpdatedPicks = () => {
        let availablePicks = [...(teamPicks[yourTeam] || [])];
        trades.forEach(trade => {
            availablePicks = availablePicks.filter(pick => !trade.sendingPicks.includes(pick));
            availablePicks = availablePicks.concat(trade.receivingPicks);
            availablePicks.sort((a, b) => pickToNumber[a] - pickToNumber[b]);
        });
        return availablePicks;
    };

    const handlePickChange = (tradeIndex, pickType, pickIndex, value) => {
        const newTrades = [...trades];
        newTrades[tradeIndex][pickType][pickIndex] = value;
        setTrades(newTrades);
    };

    return (
        <div className="calculator-container">
            <div className="calc-team-selector">
                <select className="trade-select" value={yourTeam} onChange={(e) => setYourTeam(e.target.value)}>
                    <option value="">Choose your team</option>
                    {Object.keys(teamPicks).map((team, index) => (
                        <option key={index} value={team}>{team}</option>
                    ))}
                </select>
            </div>

            {trades.map((trade, tradeIndex) => (
                <div key={`trade-${tradeIndex}`} className="trade-board">
                    <select className="trade-select" value={trade.receiverTeam} onChange={(e) => {
                        const newTrades = [...trades];
                        newTrades[tradeIndex].receiverTeam = e.target.value;
                        setTrades(newTrades);
                    }}>
                        <option value="">Choose trade partner</option>
                        {Object.keys(teamPicks).map((team, index) => (
                            <option key={index} value={team}>{team}</option>
                        ))}
                    </select>

                    <div className="trade-sides">
                        <div className="side">
                            <h4>Sending</h4>
                            {trade.sendingPicks.map((pick, index) => (
                                <div key={`sending-${tradeIndex}-${index}`} className="pick-row">
                                    <select
                                        className="trade-select"
                                        value={pick}
                                        onChange={(e) => handlePickChange(tradeIndex, 'sendingPicks', index, e.target.value)}
                                    >
                                        <option value="">Choose Pick</option>
                                        {getUpdatedPicks().map((availablePick, idx) => (
                                            <option key={idx} value={availablePick}>{availablePick}</option>
                                        ))}
                                    </select>
                                    {pick && <div className="selected-pick">{pick}</div>}
                                </div>
                            ))}
                        </div>

                        <div className="side">
                            <h4>Receiving</h4>
                            {trade.receivingPicks.map((pick, index) => (
                                <div key={`receiving-${tradeIndex}-${index}`} className="pick-row">
                                    <select
                                        className="trade-select"
                                        value={pick}
                                        onChange={(e) => handlePickChange(tradeIndex, 'receivingPicks', index, e.target.value)}
                                    >
                                        <option value="">Choose Pick</option>
                                        {(teamPicks[trade.receiverTeam] || []).map((availablePick, idx) => (
                                            <option key={idx} value={availablePick}>{availablePick}</option>
                                        ))}
                                    </select>
                                    {pick && <div className="selected-pick">{pick}</div>}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="trade-controls">
                        <button className="button-other" onClick={() => handleAddPick(tradeIndex)}>+</button>
                        <button className="button-other" onClick={() => handleRemovePick(tradeIndex)}>-</button>
                    </div>
                </div>
            ))}

            <button className="button-other" onClick={handleAddTradePartner}>Add Trade Partner</button>

            <div className="results">
                <h3>Results</h3>
                {trades.map((trade, index) => {
                    const { valueDifference, positionsDifference } = calculateDifference(trade.sendingPicks, trade.receivingPicks);
                    return (
                        <div key={`result-${index}`} className="result">
                            <strong>Trade {index + 1}</strong>
                            <div>
                                <strong>Value Difference:</strong> {valueDifference.toFixed(2)}{' '}
                                {valueDifference > 0 ? <img src="positive.png" alt="Positive" /> : <img src="negative.png" alt="Negative" />}
                            </div>
                            <div>
                                <strong>Positions Moved:</strong> {positionsDifference}
                                {positionsDifference > 0 ? <img src="positive.png" alt="Positive" /> : <img src="negative.png" alt="Negative" />}
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className="updated-picks">
                <h3>Updated Picks for {yourTeam}</h3>
                <table className="updated-picks-table">
                    <thead>
                        <tr>
                            <th>Round</th>
                            <th>Pick</th>
                            <th>Overall</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getUpdatedPicks().map((pick, index) => (
                            <tr key={index}>
                                <td>{pick.split('.')[0]}</td>
                                <td>{pick}</td>
                                <td>{pickToNumber[pick]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DraftCalculator;
