import React, { useState, useEffect } from 'react';
import './DraftPicks.css';
import DraftCalculator from './DraftCalculator';

// Team names
const teams = [
  "All Teams",
  "Filter",
  "AJ",
  "Kenny",
  "D",
  "Gary",
  "Manny",
  "Izzy",
  "Scheck",
  "Zo",
  "Chris",
  "Terry",
  "Rob"
];

// TRADES!
const trades = [
  { tradeId: 1, from: "Filter", to: "Scheck", picks: ["1.1", "11.1"], date: "August 1, 2024" },
  { tradeId: 1, from: "Scheck", to: "Filter", picks: ["2.5", "3.8"], date: "August 1, 2024" },
  { tradeId: 2, from: "AJ", to: "Manny", picks: ["1.2", "7.2"], date: "August 12, 2024" },
  { tradeId: 2, from: "Manny", to: "AJ", picks: ["1.6", "5.6"], date: "August 12, 2024" },
  { tradeId: 3, from: "Rob", to: "Terry", picks: ["1.12", "10.1"], date: "August 15, 2024" },
  { tradeId: 3, from: "Terry", to: "Rob", picks: ["3.11", "4.2"], date: "August 15, 2024" },
  { tradeId: 4, from: "Rob", to: "Zo", picks: ["4.1", "7.12", "9.12"], date: "August 15, 2024" },
  { tradeId: 4, from: "Zo", to: "Rob", picks: ["5.9", "6.4", "7.9"], date: "August 15, 2024" },
  { tradeId: 5, from: "AJ", to: "Rob", picks: ["8.11", "9.2"], date: "August 15, 2024" },
  { tradeId: 5, from: "Rob", to: "AJ", picks: ["5.12", "17.12"], date: "August 15, 2024" },
  { tradeId: 6, from: "Izzy", to: "Zo", picks: ["3.7", "4.6", "9.7"], date: "August 17, 2024" },
  { tradeId: 6, from: "Zo", to: "Izzy", picks: ["2.4", "8.4", "13.9"], date: "August 17, 2024" },
  { tradeId: 7, from: "Filter", to: "Zo", picks: ["5.1", "6.12"], date: "August 17, 2024" },
  { tradeId: 7, from: "Zo", to: "Filter", picks: ["3.9", "11.9"], date: "August 17, 2024" },
  { tradeId: 8, from: "Filter", to: "Zo", picks: ["4.12", "12.12"], date: "August 19, 2024" },
  { tradeId: 8, from: "Zo", to: "Filter", picks: ["6.12", "7.12"], date: "August 19, 2024" },
  { tradeId: 9, from: "Rob", to: "Gary", picks: ["2.1", "3.12", "14.1"], date: "August 19, 2024" },
  { tradeId: 9, from: "Gary", to: "Rob", picks: ["2.8", "3.5", "10.8"], date: "August 19, 2024" },
  { tradeId: 10, from: "Izzy", to: "Zo", picks: ["5.7","7.7"], date: "August 20, 2024" },
  { tradeId: 10, from: "Zo", to: "Izzy", picks: ["4.12", "9.7"], date: "August 20, 2024" },
  { tradeId: 11, from: "Rob", to: "Filter", picks: ["6.4", "15.12"], date: "August 22, 2024" },
  { tradeId: 11, from: "Filter", to: "Rob", picks: ["7.12", "8.12"], date: "August 22, 2024" },
  { tradeId: 12, from: "Rob", to: "Zo", picks: ["2.8", "8.1", "9.2", "13.12"], date: "September 01, 2024" },
  { tradeId: 12, from: "Zo", to: "Rob", picks: ["3.7", "5.1", "10.4", "12.12"], date: "September 01, 2024" },
];

// GENERATE PICKS
const generatePicks = () => {
  const picks = [];
  const totalPicks = 204;
  const totalTeams = 12;

  for (let i = 0; i < totalPicks; i++) {
    const round = Math.floor(i / totalTeams) + 1;
    const pickInRound = (i % totalTeams) + 1;
    const overallPick = i + 1;
    const teamIndex = round % 2 === 0 ? totalTeams - pickInRound : pickInRound - 1;
    picks.push({
      number: `${round}.${pickInRound}`,
      overall: overallPick,
      team: teams[teamIndex + 1],
      originalTeam: teams[teamIndex + 1],
      traded: false,
      from: null,  // Initialize `from` field
    });
  }

  // Apply trades using tradeId
  trades.forEach(trade => {
    trade.picks.forEach(pickNumber => {
      const pickIndex = picks.findIndex(pick => pick.number === pickNumber);
      if (pickIndex !== -1) {
        picks[pickIndex].team = trade.to;
        picks[pickIndex].traded = true;
        picks[pickIndex].from = trade.from;
        picks[pickIndex].tradeId = trade.tradeId;  // Assign tradeId to each pick
      }
    });
  });

  return picks;
};

const DraftPicks = () => {
  const picks = generatePicks();
  const [selectedTeam, setSelectedTeam] = useState("All Teams");
  const [view, setView] = useState("Picks");

  const handleChange = (event) => {
    setSelectedTeam(event.target.value);
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const highestTradeId = Math.max(...trades.map(trade => trade.tradeId));
  const progressPercentage = Math.min((highestTradeId / 100) * 100, 100);

  useEffect(() => {
    // Hide footer when on Draft Board page
    const footer = document.querySelector('footer');
    if (footer) {
      footer.style.display = 'none';
    }
    // Show footer when leaving Draft Board page
    return () => {
      if (footer) {
        footer.style.display = 'block';
      }
    };
  }, []);

  return (
    <div className="draft-picks-container">
      <div className="draftnav">
        <button className={view === "Picks" ? "active" : ""} onClick={() => handleViewChange("Picks")}>Picks</button>
        <button className={view === "Teams" ? "active" : ""} onClick={() => handleViewChange("Teams")}>Teams</button>
        <button className={view === "Trades" ? "active" : ""} onClick={() => handleViewChange("Trades")}>Trades</button>
        <button className={view === "Calc" ? "active" : ""} onClick={() => handleViewChange("Calc")}>Calc</button>
      </div>

      {view === "Picks" && (
        <table className="draft-picks-table">
          <thead>
            <tr>
              <th>Overall</th>
              <th>Pick Number</th>
              <th>Team</th>
              <th>Trade</th>
            </tr>
          </thead>
          <tbody>
            {picks.map((pick, index) => (
              <tr key={index} className={Math.floor((pick.overall - 1) / 12) % 2 === 0 ? "even-round" : "odd-round"}>
                <td className="overall">{pick.overall}</td>
                <td className="number">{pick.number}</td>
                <td className="team">{pick.team}</td>
                <td className="trade">
                  {pick.traded && (
                    <>
                      <img src="trade.svg" alt="Traded" className="trade-icon" />
                      <span className="table-trade">from {pick.from}</span>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {view === "Teams" && (
        <div className="teams-view">
          <div className="custom-select">
            <select className="team-select" value={selectedTeam} onChange={handleChange}>
              <option value="">Choose a Team</option>
              {teams.slice(1).map((team, index) => ( // Exclude "All Teams"
                <option key={index} value={team}>
                  {team}
                </option>
              ))}
            </select>
          </div>
          <table className="team-picks-table">
            <thead>
              <tr>
                <th>Overall</th>
                <th>Pick Number</th>
                <th>Origin</th>
              </tr>
            </thead>
            <tbody>
              {picks
                .filter(pick => pick.team === selectedTeam)
                .map((pick, index) => (
                  <tr
                    key={index}
                    className={`${Math.floor((pick.overall - 1) / 12) % 2 === 0 ? "even-round" : "odd-round"} ${
                      index === 7 ? "ninth-row" : ""
                    }`}
                  >
                    <td className="overall">{pick.overall}</td>
                    <td className="number">{pick.number}</td>
                    <td className="team">{pick.from}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {view === "Trades" && (
        <div className="trade-view-parent">
          <div className="total-trades-meter">
            <label>Total Trades: {highestTradeId}</label>
            <div className="meter">
              <div
                className="meter-fill"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
          </div>
          <div className="trades-view">
            {Array.from(new Set(trades.map(trade => trade.tradeId))).map(tradeId => {
              const tradeGroup = trades.filter(trade => trade.tradeId === tradeId);
              return (
                <div key={tradeId} className="trade-row">
                  <div className="trade-id-card">
                    <div>{tradeId}</div>
                  </div>
                  <div className="trade-card">
                    <div className="trade-card-header">
                      <span className="trade-card-date">{tradeGroup[0].date}</span>
                    </div>
                    <div className="trade-card-content">
                      {tradeGroup.map((trade, index) => (
                        <div key={index} className="trade-team">
                          <strong>{trade.from}</strong> sends {trade.picks.join(", ")}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {view === "Calc" && (
        <DraftCalculator />
      )}
    </div>
  );
};

export default DraftPicks;