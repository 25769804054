import React from 'react';
import './PastChampions.css';

const champions = [
  {
    name: "Scheckman",
    year: "2023"
  },
  {
    name: "Manny",
    year: "2022"
  },
  {
    name: "Bobby",
    year: "2021"
  },
  {
    name: "Filter",
    year: "2020"
  },
  {
    name: "AJ",
    year: "2019"
  },
  {
    name: "Terry",
    year: "2018"
  },
  {
    name: "Terry",
    year: "2017"
  }
];



const PastChampions = () => {
  return (
    <section id="pastchamps">
      <div className="champion-container">
        {champions.map((champion, index) => (
          <div key={index} className="champion-box">
            <div className="champion-name">{champion.name}</div>
            <div className="champion-year">{champion.year}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PastChampions;
